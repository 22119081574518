/* You can add global styles to this file, and also import other style files */
.mat-black {
  // background-color: #565656;
  // color:white;
  background-image: linear-gradient(#686868, #424242) !important;
  color: white !important;
  border-style: solid !important;
  border-color: #565656 !important;
  border-width: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  
}

.mat-white {
  background-color: white;
  color:#434343;
}

.mat-white-border {
  background-color: #ffffff;
  color: #565656;
  border-style: solid !important;
  border-color: #565656 !important;
  border-width: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  padding: 0 16px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle , .mat-radio-outer-circle {
  border-color: black !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  background-color: black !important;
}

.mat-horizontal-content-container{
  padding: 0px 0px 0px 0px !important;
}
.mat-horizontal-stepper-content{
  padding: 0px 0px 0px 0px !important;
}
.mat-horizontal-stepper-header {
  padding-right: 0 !important;
}
.mat-step-label  {
  min-width: 0 !important;
}

.mat-mdc-unelevated-button[disabled][disabled] {
  background-image: linear-gradient(#e0e0e0, #f3f3f3) !important;
    color: white !important;
    border-style: solid !important;
    border-color: #e6e3e3 !important;
    border-width: 2px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  display: block !important;
  padding: 24px !important;
  border-radius: 20px !important;
  box-sizing: border-box !important;
  overflow: auto !important;
  outline: 0 !important;
  width: 100% !important;
  height: 100% !important;
  // min-width: 93vw !important;
  max-width: 93vw !important;
  position: static;
  min-height: inherit !important;
  max-height: inherit !important;
}

.mdc-dialog__container {
  min-width: 100% !important;
  max-width: 100vw !important;
  position: static;
}

// 


.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

/* Saját Globális */

.forward-button {
  width: 200px;
}

.circle {
  background: #a3a3a3;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 50px;
  margin-right: 5px;
  text-align: center;
  width: 50px;
}

.checked-in {
  color: #41c300;
}

.checked-color {
  color: #41c300;
}

.mat-black {
  background-color: #434343;
  color:white
}

.hw-card {
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
 }

 .mat-mdc-flat-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px !important;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

.mat-mdc-unelevated-button>.mat-icon, .mat-mdc-raised-button>.mat-icon, .mat-mdc-outlined-button>.mat-icon {
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}

hr.exi {
  border: 1px solid rgb(229,229,229);
}

// .mat-mdc-form-field-infix {
//   min-height: 30px !important;
// }

// .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
//   padding-top: 3px !important;
//   padding-bottom: 3px !important;
// }

// .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
//   height: 24px !important;
// }
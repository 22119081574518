/* h + p + ul + li tags */
p {
    font-family: "Roboto";
}

h1 {
    font-family: "Roboto";
}

h2 {
    font-family: "Roboto";
}

h3 {
    font-family: "Roboto";
}

h4 {
    font-family: "Roboto";
}

h5 {
    font-family: "Roboto";
}

h6 {
    font-family: "Roboto";
}

ul li {
    font-family: "Roboto";
}

/* Font colors */
.hw-red-color {
    color: red;
}

/* Grid cell */
.hw-grid-cell {
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 500;
}

.hw-grid-cell-bold {
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 800;
}

/* Title */
.hw-title-text {
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0px 20px 0px;
}

/* Container */
.hw-container-background {
    margin: -60px 40px 40px 40px;
    position: relative !important;
    padding: 2em;
    background-color: #fff;
}

@media screen and (max-width: 840px) {
    .hw-container-background {
        margin: -60px 0px 0px 0px;
        padding: 1em;
    }
}

/* Float */
.hw-float-left {
    float: left;
}

.hw-float-right {
    float: right;
}

.hw-float-none {
    float: none;
}

.hw-float-inline-end {
    float: inline-end;
}

/* div align */
.hw-div-center {
    display: flex;
    justify-content: center;
}

/* Float important */
.hw-float-left-important {
    float: left !important;
}

.hw-float-right-important {
    float: right !important;
}

.hw-float-none-important {
    float: none !important;
}

/* Webkit align */
.hw-webkit-right {
    text-align: -webkit-right;
}

.hw-webkit-left {
    text-align: -webkit-left;
}

.hw-webkit-center {
    text-align: -webkit-center;
}

/* Webkit align important*/
.hw-webkit-right-important {
    text-align: -webkit-right !important;
}

.hw-webkit-left-important {
    text-align: -webkit-left !important;
}

.hw-webkit-center-important {
    text-align: -webkit-center !important;
}

/* Displays */
.hw-display-inline-block {
    display: inline-block;
}

.hw-display-inline {
    display: inline;
}

.hw-display-inline-flex {
    display: inline-flex;
}

.hw-display-table {
    display: table;
}

.hw-display-inline-table {
    display: inline-table;
}

.hw-display-table-caption {
    display: table-caption;
}

.hw-display-table-cell {
    display: table-cell;
}

.hw-display-table-column {
    display: table-column;
}

.hw-display-table-column-group {
    display: table-column-group;
}

.hw-display-table-footer-group {
    display: table-footer-group;
}

.hw-display-table-header-group {
    display: table-header-group;
}

.hw-display-table-row-group {
    display: table-row-group;
}

.hw-display-table-row {
    display: table-row;
}

.hw-display-flow-root {
    display: flow-root;
}

.hw-display-grid {
    display: grid;
}

.hw-display-inline-grid {
    display: inline-grid;
}

.hw-display-contents {
    display: contents;
}

.hw-display-list-item {
    display: list-item;
}

.hw-display-hidden {
    display: none;
}

/* Displays important*/
.hw-display-inline-block-important {
    display: inline-block !important;
}

.hw-display-inline-important {
    display: inline !important;
}

.hw-display-inline-flex-important {
    display: inline-flex !important;
}

.hw-display-table-important {
    display: table !important;
}

.hw-display-inline-table-important {
    display: inline-table !important;
}

.hw-display-table-caption-important {
    display: table-caption !important;
}

.hw-display-table-cell-important {
    display: table-cell !important;
}

.hw-display-table-column-important {
    display: table-column !important;
}

.hw-display-table-column-group-important {
    display: table-column-group !important;
}

.hw-display-table-footer-group-important {
    display: table-footer-group !important;
}

.hw-display-table-header-group-important {
    display: table-header-group !important;
}

.hw-display-table-row-group-important {
    display: table-row-group !important;
}

.hw-display-table-row-important {
    display: table-row !important;
}

.hw-display-flow-root-important {
    display: flow-root !important;
}

.hw-display-grid-important {
    display: grid !important;
}

.hw-display-inline-grid-important {
    display: inline-grid !important;
}

.hw-display-contents-important {
    display: contents !important;
}

.hw-display-list-item {
    display: list-item !important;
}

.hw-display-hidden-important {
    display: none !important;
}

/* Overflow */
.hw-overflow-auto {
    overflow: auto;
}

.hw-overflow-hidden {
    overflow: hidden;
}

.hw-overflow-clip {
    overflow: clip;
}

.hw-overflow-visible {
    overflow: visible;
}

.hw-overflow-scroll {
    overflow: scroll;
}

.hw-overflow-x-auto {
    overflow-x: auto;
}

.hw-overflow-y-auto {
    overflow-y: auto;
}

.hw-overflow-x-hidden {
    overflow-x: hidden;
}

.hw-overflow-y-hidden {
    overflow-y: hidden;
}

.hw-overflow-x-clip {
    overflow-x: clip;
}

.hw-overflow-y-clip {
    overflow-y: clip;
}

.hw-overflow-x-visible {
    overflow-x: visible;
}

.hw-overflow-y-visible {
    overflow-y: visible;
}

.hw-overflow-x-scroll {
    overflow-x: scroll;
}

.hw-overflow-y-scroll {
    overflow-y: scroll;
}

/* Overflow important*/
.hw-overflow-auto-important {
    overflow: auto !important;
}

.hw-overflow-hidden-important {
    overflow: hidden !important;
}

.hw-overflow-clip-important {
    overflow: clip !important;
}

.hw-overflow-visible-important {
    overflow: visible !important;
}

.hw-overflow-scroll-important {
    overflow: scroll !important;
}

.hw-overflow-x-auto-important {
    overflow-x: auto !important;
}

.hw-overflow-y-auto-important {
    overflow-y: auto !important;
}

.hw-overflow-x-hidden-important {
    overflow-x: hidden !important;
}

.hw-overflow-y-hidden-important {
    overflow-y: hidden !important;
}

.hw-overflow-x-clip-important {
    overflow-x: clip !important;
}

.hw-overflow-y-clip-important {
    overflow-y: clip !important;
}

.hw-overflow-x-visible-important {
    overflow-x: visible !important;
}

.hw-overflow-y-visible-important {
    overflow-y: visible !important;
}

.hw-overflow-x-scroll-important {
    overflow-x: scroll !important;
}

.hw-overflow-y-scroll-important {
    overflow-y: scroll !important;
}

/* Overscroll behavior */
.hw-overscroll-auto {
    overscroll-behavior: auto;
}

.hw-overscroll-contain {
    overscroll-behavior: contain;
}

.hw-overscroll-none {
    overscroll-behavior: none;
}

.hw-overscroll-y-auto {
    overscroll-behavior-y: auto;
}

.hw-overscroll-y-contain {
    overscroll-behavior-y: contain;
}

.hw-overscroll-y-none {
    overscroll-behavior-y: none;
}

.hw-overscroll-x-auto {
    overscroll-behavior-x: auto;
}

.hw-overscroll-x-contain {
    overscroll-behavior-x: contain;
}

.hw-overscroll-x-none {
    overscroll-behavior-x: none;
}

/* Overscroll behavior important */
.hw-overscroll-auto-important {
    overscroll-behavior: auto !important;
}

.hw-overscroll-contain-important {
    overscroll-behavior: contain !important;
}

.hw-overscroll-none-important {
    overscroll-behavior: none !important;
}

.hw-overscroll-y-auto-important {
    overscroll-behavior-y: auto !important;
}

.hw-overscroll-y-contain-important {
    overscroll-behavior-y: contain !important;
}

.hw-overscroll-y-none-important {
    overscroll-behavior-y: none !important;
}

.hw-overscroll-x-auto-important {
    overscroll-behavior-x: auto !important;
}

.hw-overscroll-x-contain-important {
    overscroll-behavior-x: contain !important;
}

.hw-overscroll-x-none-important {
    overscroll-behavior-x: none !important;
}

/* Static position */
.hw-position-static {
    position: static;
}

.hw-position-fixed {
    position: fixed;
}

.hw-position-absolute {
    position: absolute;
}

.hw-position-relative {
    position: relative;
}

.hw-position-sticky {
    position: sticky;
}

/* Static position important */
.hw-position-static-important {
    position: static !important;
}

.hw-position-fixed-important {
    position: fixed !important;
}

.hw-position-absolute-important {
    position: absolute !important;
}

.hw-position-relative-important {
    position: relative !important;
}

.hw-position-sticky-important {
    position: sticky !important;
}

/* Padding EM */
.hw-padding-0em {
    padding: 0em;
}

.hw-padding-05em {
    padding: 0.5em;
}

.hw-padding-1em {
    padding: 1em;
}

.hw-padding-2em {
    padding: 2em;
}

.hw-padding-3em {
    padding: 3em;
}

.hw-padding-4em {
    padding: 4em;
}

.hw-padding-5em {
    padding: 5em;
}

.hw-padding-6em {
    padding: 6em;
}

.hw-padding-7em {
    padding: 7em;
}

.hw-padding-8em {
    padding: 8em;
}

.hw-padding-9em {
    padding: 9em;
}

.hw-padding-10em {
    padding: 10em;
}

.hw-padding-15em {
    padding: 15em;
}

.hw-padding-20em {
    padding: 20em;
}

/* Padding EM important */
.hw-padding-0em {
    padding: 0em !important;
}

.hw-padding-05em-important {
    padding: 0.5em !important;
}

.hw-padding-1em-important {
    padding: 1em !important;
}

.hw-padding-2em-important {
    padding: 2em !important;
}

.hw-padding-3em-important {
    padding: 3em !important;
}

.hw-padding-4em-important {
    padding: 4em !important;
}

.hw-padding-5em-important {
    padding: 5em !important;
}

.hw-padding-6em-important {
    padding: 6em !important;
}

.hw-padding-7em-important {
    padding: 7em !important;
}

.hw-padding-8em-important {
    padding: 8em !important;
}

.hw-padding-9em-important {
    padding: 9em !important;
}

.hw-padding-10em-important {
    padding: 10em !important;
}

.hw-padding-15em-important {
    padding: 15em !important;
}

.hw-padding-20em-important {
    padding: 20em !important;
}

/* Negative Padding EM */
.hw-padding--05em {
    padding: -0.5em;
}

.hw-padding--1em {
    padding: -1em;
}

.hw-padding--2em {
    padding: -2em;
}

.hw-padding--3em {
    padding: -3em;
}

.hw-padding--4em {
    padding: -4em;
}

.hw-padding--5em {
    padding: -5em;
}

.hw-padding--6em {
    padding: -6em;
}

.hw-padding--7em {
    padding: -7em;
}

.hw-padding--8em {
    padding: -8em;
}

.hw-padding--9em {
    padding: -9em;
}

.hw-padding--10em {
    padding: -10em;
}

.hw-padding--15em {
    padding: -15em;
}

.hw-padding--20em {
    padding: -20em;
}

/* Negative Padding EM important */
.hw-padding--05em-important {
    padding: -0.5em !important;
}

.hw-padding--1em-important {
    padding: -1em !important;
}

.hw-padding--2em-important {
    padding: -2em !important;
}

.hw-padding--3em-important {
    padding: -3em !important;
}

.hw-padding--4em-important {
    padding: -4em !important;
}

.hw-padding--5em-important {
    padding: -5em !important;
}

.hw-padding--6em-important {
    padding: -6em !important;
}

.hw-padding--7em-important {
    padding: -7em !important;
}

.hw-padding--8em-important {
    padding: -8em !important;
}

.hw-padding--9em-important {
    padding: -9em !important;
}

.hw-padding--10em-important {
    padding: -10em !important;
}

.hw-padding--15em-important {
    padding: -15em !important;
}

.hw-padding--20em-important {
    padding: -20em !important;
}

/* Padding PX */
.hw-padding-0px {
    padding: 0px;
}

.hw-padding-3px {
    padding: 3px;
}

.hw-padding-5px {
    padding: 5px;
}

.hw-padding-10px {
    padding: 10px;
}

.hw-padding-15px {
    padding: 15px;
}

.hw-padding-20px {
    padding: 20px;
}

/* Padding PX important */
.hw-padding-0px-important {
    padding: 0px !important;
}

.hw-padding-3px-important {
    padding: 3px !important;
}

.hw-padding-5px-important {
    padding: 5px !important;
}

.hw-padding-10px-important {
    padding: 10px !important;
}

.hw-padding-15px-important {
    padding: 15px !important;
}

.hw-padding-20px-important {
    padding: 20px !important;
}

/* Negative Padding PX */
.hw-padding--3px {
    padding: -3px;
}

.hw-padding--5px {
    padding: -5px;
}

.hw-padding--10px {
    padding: -10px;
}

.hw-padding--15px {
    padding: -15px;
}

.hw-padding--20px {
    padding: -20px;
}

/* Negative Padding PX important */
.hw-padding--3px-important {
    padding: -3px !important;
}

.hw-padding--5px-important {
    padding: -5px !important;
}

.hw-padding--10px-important {
    padding: -10px !important;
}

.hw-padding--15px-important {
    padding: -15px !important;
}

.hw-padding--20px-important {
    padding: -20px !important;
}

/* Margin Auto */
.hw-margin-auto {
    margin: auto;
}

.hw-margin-left-auto {
    margin-left: auto;
}

.hw-margin-right-auto {
    margin-left: auto;
}

.hw-margin-top-auto {
    margin-top: auto;
}

.hw-margin-bottom-auto {
    margin-bottom: auto;
}

/* Padding Auto */
.hw-padding-auto {
    padding: auto;
}

.hw-padding-left-auto {
    padding-left: auto;
}

.hw-padding-right-auto {
    padding-left: auto;
}

.hw-padding-top-auto {
    padding-top: auto;
}

.hw-padding-bottom-auto {
    padding-bottom: auto;
}

/* Margin Auto important */
.hw-margin-auto-important {
    margin: auto !important;
}

.hw-margin-left-auto-important {
    margin-left: auto !important;
}

.hw-margin-right-auto-important {
    margin-left: auto !important;
}

.hw-margin-top-auto-important {
    margin-top: auto !important;
}

.hw-margin-bottom-auto-important {
    margin-bottom: auto !important;
}

/* Padding Auto important*/
.hw-padding-auto-important {
    padding: auto !important;
}

.hw-padding-left-auto-important {
    padding-left: auto !important;
}

.hw-padding-right-auto-important {
    padding-left: auto !important;
}

.hw-padding-top-auto-important {
    padding-top: auto !important;
}

.hw-padding-bottom-auto-important {
    padding-bottom: auto !important;
}

/* Margin EM */
.hw-margin-0em {
    margin: 0em;
}

.hw-margin-05em {
    margin: 0.5em;
}

.hw-margin-1em {
    margin: 1em;
}

.hw-margin-2em {
    margin: 2em;
}

.hw-margin-3em {
    margin: 3em;
}

.hw-margin-4em {
    margin: 4em;
}

.hw-margin-5em {
    margin: 5em;
}

.hw-margin-6em {
    margin: 6em;
}

.hw-margin-7em {
    margin: 7em;
}

.hw-margin-8em {
    margin: 8em;
}

.hw-margin-9em {
    margin: 9em;
}

.hw-margin-10em {
    margin: 10em;
}

.hw-margin-15em {
    margin: 15em;
}

.hw-margin-20em {
    margin: 20em;
}

/* Margin EM important */
.hw-margin-0em-important {
    margin: 0em !important;
}

.hw-margin-05em-important {
    margin: 0.5em !important;
}

.hw-margin-1em-important {
    margin: 1em !important;
}

.hw-margin-2em-important {
    margin: 2em !important;
}

.hw-margin-3em-important {
    margin: 3em !important;
}

.hw-margin-4em-important {
    margin: 4em !important;
}

.hw-margin-5em-important {
    margin: 5em !important;
}

.hw-margin-6em-important {
    margin: 6em !important;
}

.hw-margin-7em-important {
    margin: 7em !important;
}

.hw-margin-8em-important {
    margin: 8em !important;
}

.hw-margin-9em-important {
    margin: 9em !important;
}

.hw-margin-10em-important {
    margin: 10em !important;
}

.hw-margin-15em-important {
    margin: 15em !important;
}

.hw-margin-20em-important {
    margin: 20em !important;
}

/* Negative Margin EM */
.hw-margin--05em {
    margin: -0.5em
}

.hw-margin--1em {
    margin: -1em;
}

.hw-margin--2em {
    margin: -2em;
}

.hw-margin--3em {
    margin: -3em;
}

.hw-margin--4em {
    margin: -4em;
}

.hw-margin--5em {
    margin: -5em;
}

.hw-margin--6em {
    margin: -6em;
}

.hw-margin--7em {
    margin: -7em;
}

.hw-margin--8em {
    margin: -8em;
}

.hw-margin--9em {
    margin: -9em;
}

.hw-margin--10em {
    margin: -10em;
}

.hw-margin--15em {
    margin: -15em;
}

.hw-margin--20em {
    margin: -20em;
}

/* Negative Margin EM important */
.hw-margin--05em-important {
    margin: -0.5em !important;
}

.hw-margin--1em-important {
    margin: -1em !important;
}

.hw-margin--2em-important {
    margin: -2em !important;
}

.hw-margin--3em-important {
    margin: -3em !important;
}

.hw-margin--4em-important {
    margin: -4em !important;
}

.hw-margin--5em-important {
    margin: -5em !important;
}

.hw-margin--6em-important {
    margin: -6em !important;
}

.hw-margin--7em-important {
    margin: -7em !important;
}

.hw-margin--8em-important {
    margin: -8em !important;
}

.hw-margin--9em-important {
    margin: -9em !important;
}

.hw-margin--10em-important {
    margin: -10em !important;
}

.hw-margin--15em-important {
    margin: -15em !important;
}

.hw-margin--20em-important {
    margin: -20em !important;
}

/* Margin PX */
.hw-margin-0px {
    margin: 0px;
}

.hw-margin-3px {
    margin: 3px;
}

.hw-margin-5px {
    margin: 5px;
}

.hw-margin-10px {
    margin: 10px;
}

.hw-margin-15px {
    margin: 15px;
}

.hw-margin-20px {
    margin: 20px;
}

/* Margin PX important */
.hw-margin-0px-important {
    margin: 0px !important;
}

.hw-margin-3px-important {
    margin: 3px !important;
}

.hw-margin-5px-important {
    margin: 5px !important;
}

.hw-margin-10px-important {
    margin: 10px !important;
}

.hw-margin-15px-important {
    margin: 15px !important;
}

.hw-margin-20px-important {
    margin: 20px !important;
}

/* Negative Margin PX */
.hw-margin--3px {
    margin: -3px;
}

.hw-margin--5px {
    margin: -5px;
}

.hw-margin--10px {
    margin: -10px;
}

.hw-margin--15px {
    margin: -15px;
}

.hw-margin--20px {
    margin: -20px;
}

/* Negative Margin PX important */
.hw-margin--3px-important {
    margin: -3px !important;
}

.hw-margin--5px-important {
    margin: -5px !important;
}

.hw-margin--10px-important {
    margin: -10px !important;
}

.hw-margin--15px-important {
    margin: -15px !important;
}

.hw-margin--20px-important {
    margin: -20px !important;
}

/* Padding-top EM */
.hw-padding-top-0em {
    padding-top: 0em;
}

.hw-padding-top-05em {
    padding-top: 0.5em;
}

.hw-padding-top-1em {
    padding-top: 1em;
}

.hw-padding-top-2em {
    padding-top: 2em;
}

.hw-padding-top-3em {
    padding-top: 3em;
}

.hw-padding-top-4em {
    padding-top: 4em;
}

.hw-padding-top-5em {
    padding-top: 5em;
}

.hw-padding-top-6em {
    padding-top: 6em;
}

.hw-padding-top-7em {
    padding-top: 7em;
}

.hw-padding-top-8em {
    padding-top: 8em;
}

.hw-padding-top-9em {
    padding-top: 9em;
}

.hw-padding-top-10em {
    padding-top: 10em;
}

.hw-padding-top-15em {
    padding-top: 15em;
}

.hw-padding-top-20em {
    padding-top: 20em;
}

/* Padding-top EM important */
.hw-padding-top-0em-important {
    padding-top: 0em !important;
}

.hw-padding-top-05em-important {
    padding-top: 0.5em !important;
}

.hw-padding-top-1em-important {
    padding-top: 1em !important;
}

.hw-padding-top-2em-important {
    padding-top: 2em !important;
}

.hw-padding-top-3em-important {
    padding-top: 3em !important;
}

.hw-padding-top-4em-important {
    padding-top: 4em !important;
}

.hw-padding-top-5em-important {
    padding-top: 5em !important;
}

.hw-padding-top-6em-important {
    padding-top: 6em !important;
}

.hw-padding-top-7em-important {
    padding-top: 7em !important;
}

.hw-padding-top-8em-important {
    padding-top: 8em !important;
}

.hw-padding-top-9em-important {
    padding-top: 9em !important;
}

.hw-padding-top-10em-important {
    padding-top: 10em !important;
}

.hw-padding-top-15em-important {
    padding-top: 15em !important;
}

.hw-padding-top-20em-important {
    padding-top: 20em !important;
}

/* Padding-top PX */
.hw-padding-top-0px {
    padding-top: 0px;
}

.hw-padding-top-3px {
    padding-top: 3px;
}

.hw-padding-top-5px {
    padding-top: 5px;
}

.hw-padding-top-10px {
    padding-top: 10px;
}

.hw-padding-top-15px {
    padding-top: 15px;
}

.hw-padding-top-20px {
    padding-top: 20px;
}

/* Padding-top PX important */
.hw-padding-top-0px-important {
    padding-top: 0px !important;
}

.hw-padding-top-3px-important {
    padding-top: 3px !important;
}

.hw-padding-top-5px-important {
    padding-top: 5px !important;
}

.hw-padding-top-10px-important {
    padding-top: 10px !important;
}

.hw-padding-top-15px-important {
    padding-top: 15px !important;
}

.hw-padding-top-20px-important {
    padding-top: 20px !important;
}

/* Padding-right EM */
.hw-padding-right-0em {
    padding-right: 0em;
}

.hw-padding-right-05em {
    padding-right: 0.5em;
}

.hw-padding-right-1em {
    padding-right: 1em;
}

.hw-padding-right-2em {
    padding-right: 2em;
}

.hw-padding-right-3em {
    padding-right: 3em;
}

.hw-padding-right-4em {
    padding-right: 4em;
}

.hw-padding-right-5em {
    padding-right: 5em;
}

.hw-padding-right-6em {
    padding-right: 6em;
}

.hw-padding-right-7em {
    padding-right: 7em;
}

.hw-padding-right-8em {
    padding-right: 8em;
}

.hw-padding-right-9em {
    padding-right: 9em;
}

.hw-padding-right-10em {
    padding-right: 10em;
}

.hw-padding-right-15em {
    padding-right: 15em;
}

.hw-padding-right-20em {
    padding-right: 20em;
}

/* Padding-right EM important */
.hw-padding-right-0em-important {
    padding-right: 0em !important;
}

.hw-padding-right-05em-important {
    padding-right: 0.5em !important;
}

.hw-padding-right-1em-important {
    padding-right: 1em !important;
}

.hw-padding-right-2em-important {
    padding-right: 2em !important;
}

.hw-padding-right-3em-important {
    padding-right: 3em !important;
}

.hw-padding-right-4em-important {
    padding-right: 4em !important;
}

.hw-padding-right-5em-important {
    padding-right: 5em !important;
}

.hw-padding-right-6em-important {
    padding-right: 6em !important;
}

.hw-padding-right-7em-important {
    padding-right: 7em !important;
}

.hw-padding-right-8em-important {
    padding-right: 8em !important;
}

.hw-padding-right-9em-important {
    padding-right: 9em !important;
}

.hw-padding-right-10em-important {
    padding-right: 10em !important;
}

.hw-padding-right-15em-important {
    padding-right: 15em !important;
}

.hw-padding-right-20em-important {
    padding-right: 20em !important;
}

/* Padding-right PX */
.hw-padding-right-0px {
    padding-right: 0px;
}

.hw-padding-right-3px {
    padding-right: 3px;
}

.hw-padding-right-5px {
    padding-right: 5px;
}

.hw-padding-right-10px {
    padding-right: 10px;
}

.hw-padding-right-15px {
    padding-right: 15px;
}

.hw-padding-right-20px {
    padding-right: 20px;
}

/* Padding-right PX important */
.hw-padding-right-0px-important {
    padding-right: 0px !important;
}

.hw-padding-right-3px-important {
    padding-right: 3px !important;
}

.hw-padding-right-5px-important {
    padding-right: 5px !important;
}

.hw-padding-right-10px-important {
    padding-right: 10px !important;
}

.hw-padding-right-15px-important {
    padding-right: 15px !important;
}

.hw-padding-right-20px-important {
    padding-right: 20px !important;
}

/* Padding-bottom EM */
.hw-padding-bottom-0em {
    padding-bottom: 0em;
}

.hw-padding-bottom-05em {
    padding-bottom: 0.5em;
}

.hw-padding-bottom-1em {
    padding-bottom: 1em;
}

.hw-padding-bottom-2em {
    padding-bottom: 2em;
}

.hw-padding-bottom-3em {
    padding-bottom: 3em;
}

.hw-padding-bottom-4em {
    padding-bottom: 4em;
}

.hw-padding-bottom-5em {
    padding-bottom: 5em;
}

.hw-padding-bottom-6em {
    padding-bottom: 6em;
}

.hw-padding-bottom-7em {
    padding-bottom: 7em;
}

.hw-padding-bottom-8em {
    padding-bottom: 8em;
}

.hw-padding-bottom-9em {
    padding-bottom: 9em;
}

.hw-padding-bottom-10em {
    padding-bottom: 10em;
}

.hw-padding-bottom-15em {
    padding-bottom: 15em;
}

.hw-padding-bottom-20em {
    padding-bottom: 20em;
}

/* Padding-bottom EM important */
.hw-padding-bottom-0em-important {
    padding-bottom: 0em !important;
}

.hw-padding-bottom-05em-important {
    padding-bottom: 0.5em !important;
}

.hw-padding-bottom-1em-important {
    padding-bottom: 1em !important;
}

.hw-padding-bottom-2em-important {
    padding-bottom: 2em !important;
}

.hw-padding-bottom-3em-important {
    padding-bottom: 3em !important;
}

.hw-padding-bottom-4em-important {
    padding-bottom: 4em !important;
}

.hw-padding-bottom-5em-important {
    padding-bottom: 5em !important;
}

.hw-padding-bottom-6em-important {
    padding-bottom: 6em !important;
}

.hw-padding-bottom-7em-important {
    padding-bottom: 7em !important;
}

.hw-padding-bottom-8em-important {
    padding-bottom: 8em !important;
}

.hw-padding-bottom-9em-important {
    padding-bottom: 9em !important;
}

.hw-padding-bottom-10em-important {
    padding-bottom: 10em !important;
}

.hw-padding-bottom-15em-important {
    padding-bottom: 15em !important;
}

.hw-padding-bottom-20em-important {
    padding-bottom: 20em !important;
}

/* Padding-bottom PX */
.hw-padding-bottom-0px {
    padding-bottom: 0px;
}

.hw-padding-bottom-3px {
    padding-bottom: 3px;
}

.hw-padding-bottom-5px {
    padding-bottom: 5px;
}

.hw-padding-bottom-10px {
    padding-bottom: 10px;
}

.hw-padding-bottom-15px {
    padding-bottom: 15px;
}

.hw-padding-bottom-20px {
    padding-bottom: 20px;
}

/* Padding-bottom PX important */
.hw-padding-bottom-0px-important {
    padding-bottom: 0px !important;
}

.hw-padding-bottom-3px-important {
    padding-bottom: 3px !important;
}

.hw-padding-bottom-5px-important {
    padding-bottom: 5px !important;
}

.hw-padding-bottom-10px-important {
    padding-bottom: 10px !important;
}

.hw-padding-bottom-15px-important {
    padding-bottom: 15px !important;
}

.hw-padding-bottom-20px-important {
    padding-bottom: 20px !important;
}

/* Padding-left EM */
.hw-padding-left-0em {
    padding-left: 0em;
}

.hw-padding-left-05em {
    padding-left: 0.5em;
}

.hw-padding-left-1em {
    padding-left: 1em;
}

.hw-padding-left-2em {
    padding-left: 2em;
}

.hw-padding-left-3em {
    padding-left: 3em;
}

.hw-padding-left-4em {
    padding-left: 4em;
}

.hw-padding-left-5em {
    padding-left: 5em;
}

.hw-padding-left-6em {
    padding-left: 6em;
}

.hw-padding-left-7em {
    padding-left: 7em;
}

.hw-padding-left-8em {
    padding-left: 8em;
}

.hw-padding-left-9em {
    padding-left: 9em;
}

.hw-padding-left-10em {
    padding-left: 10em;
}

.hw-padding-left-15em {
    padding-left: 15em;
}

.hw-padding-left-20em {
    padding-left: 20em;
}

/* Padding-left EM important */
.hw-padding-left-0em-important {
    padding-left: 0em !important;
}

.hw-padding-left-05em-important {
    padding-left: 0.5em !important;
}

.hw-padding-left-1em-important {
    padding-left: 1em !important;
}

.hw-padding-left-2em-important {
    padding-left: 2em !important;
}

.hw-padding-left-3em-important {
    padding-left: 3em !important;
}

.hw-padding-left-4em-important {
    padding-left: 4em !important;
}

.hw-padding-left-5em-important {
    padding-left: 5em !important;
}

.hw-padding-left-6em-important {
    padding-left: 6em !important;
}

.hw-padding-left-7em-important {
    padding-left: 7em !important;
}

.hw-padding-left-8em-important {
    padding-left: 8em !important;
}

.hw-padding-left-9em-important {
    padding-left: 9em !important;
}

.hw-padding-left-10em-important {
    padding-left: 10em !important;
}

.hw-padding-left-15em-important {
    padding-left: 15em !important;
}

.hw-padding-left-20em-important {
    padding-left: 20em !important;
}

/* Padding-left PX */
.hw-padding-left-0px {
    padding-left: 0px;
}

.hw-padding-left-3px {
    padding-left: 3px;
}

.hw-padding-left-5px {
    padding-left: 5px;
}

.hw-padding-left-10px {
    padding-left: 10px;
}

.hw-padding-left-15px {
    padding-left: 15px;
}

.hw-padding-left-20px {
    padding-left: 20px;
}

/* Padding-left PX important */
.hw-padding-left-0px-important {
    padding-left: 0px !important;
}

.hw-padding-left-3px-important {
    padding-left: 3px !important;
}

.hw-padding-left-5px-important {
    padding-left: 5px !important;
}

.hw-padding-left-10px-important {
    padding-left: 10px !important;
}

.hw-padding-left-15px-important {
    padding-left: 15px !important;
}

.hw-padding-left-20px-important {
    padding-left: 20px !important;
}

/* Margin-top EM */
.hw-margin-top-0em {
    margin-top: 0em;
}

.hw-margin-top-05em {
    margin-top: 0.5em;
}

.hw-margin-top-1em {
    margin-top: 1em;
}

.hw-margin-top-2em {
    margin-top: 2em;
}

.hw-margin-top-3em {
    margin-top: 3em;
}

.hw-margin-top-4em {
    margin-top: 4em;
}

.hw-margin-top-5em {
    margin-top: 5em;
}

.hw-margin-top-6em {
    margin-top: 6em;
}

.hw-margin-top-7em {
    margin-top: 7em;
}

.hw-margin-top-8em {
    margin-top: 8em;
}

.hw-margin-top-9em {
    margin-top: 9em;
}

.hw-margin-top-10em {
    margin-top: 10em;
}

.hw-margin-top-15em {
    margin-top: 15em;
}

.hw-margin-top-20em {
    margin-top: 20em;
}

/* Margin-top EM important */
.hw-margin-top--05em-important {
    margin-top: -0.5em !important;
}

.hw-margin-top-0em-important {
    margin-top: 0em !important;
}

.hw-margin-top-05em-important {
    margin-top: 0.5em !important;
}

.hw-margin-top-1em-important {
    margin-top: 1em !important;
}

.hw-margin-top-2em-important {
    margin-top: 2em !important;
}

.hw-margin-top-3em-important {
    margin-top: 3em !important;
}

.hw-margin-top-4em-important {
    margin-top: 4em !important;
}

.hw-margin-top-5em-important {
    margin-top: 5em !important;
}

.hw-margin-top-6em-important {
    margin-top: 6em !important;
}

.hw-margin-top-7em-important {
    margin-top: 7em !important;
}

.hw-margin-top-8em-important {
    margin-top: 8em !important;
}

.hw-margin-top-9em-important {
    margin-top: 9em !important;
}

.hw-margin-top-10em-important {
    margin-top: 10em !important;
}

.hw-margin-top-15em-important {
    margin-top: 15em !important;
}

.hw-margin-top-20em-important {
    margin-top: 20em !important;
}

/* Margin-top PX */
.hw-margin-top-0px {
    margin-top: 0px;
}

.hw-margin-top-3px {
    margin-top: 3px;
}

.hw-margin-top-5px {
    margin-top: 5px;
}

.hw-margin-top-10px {
    margin-top: 10px;
}

.hw-margin-top-15px {
    margin-top: 15px;
}

.hw-margin-top-20px {
    margin-top: 20px;
}

/* Margin-top PX important */
.hw-margin-top-0px-important {
    margin-top: 0px !important;
}

.hw-margin-top-3px-important {
    margin-top: 3px !important;
}

.hw-margin-top-5px-important {
    margin-top: 5px !important;
}

.hw-margin-top-10px-important {
    margin-top: 10px !important;
}

.hw-margin-top-15px-important {
    margin-top: 15px !important;
}

.hw-margin-top-20px-important {
    margin-top: 20px !important;
}

/* Margin-right EM */
.hw-margin-right-0em {
    margin-right: 0em;
}

.hw-margin-right-02em {
    margin-right: 0.2em;
}

.hw-margin-right-03em {
    margin-right: 0.3em;
}

.hw-margin-right-05em {
    margin-right: 0.5em;
}

.hw-margin-right-1em {
    margin-right: 1em;
}

.hw-margin-right-2em {
    margin-right: 2em;
}

.hw-margin-right-3em {
    margin-right: 3em;
}

.hw-margin-right-4em {
    margin-right: 4em;
}

.hw-margin-right-5em {
    margin-right: 5em;
}

.hw-margin-right-6em {
    margin-right: 6em;
}

.hw-margin-right-7em {
    margin-right: 7em;
}

.hw-margin-right-8em {
    margin-right: 8em;
}

.hw-margin-right-9em {
    margin-right: 9em;
}

.hw-margin-right-10em {
    margin-right: 10em;
}

.hw-margin-right-15em {
    margin-right: 15em;
}

.hw-margin-right-20em {
    margin-right: 20em;
}

/* Margin-right EM important */
.hw-margin-right-0em-important {
    margin-right: 0em !important;
}

.hw-margin-right-05em-important {
    margin-right: 0.5em !important;
}

.hw-margin-right-1em-important {
    margin-right: 1em !important;
}

.hw-margin-right-2em-important {
    margin-right: 2em !important;
}

.hw-margin-right-3em-important {
    margin-right: 3em !important;
}

.hw-margin-right-4em-important {
    margin-right: 4em !important;
}

.hw-margin-right-5em-important {
    margin-right: 5em !important;
}

.hw-margin-right-6em-important {
    margin-right: 6em !important;
}

.hw-margin-right-7em-important {
    margin-right: 7em !important;
}

.hw-margin-right-8em-important {
    margin-right: 8em !important;
}

.hw-margin-right-9em-important {
    margin-right: 9em !important;
}

.hw-margin-right-10em-important {
    margin-right: 10em !important;
}

.hw-margin-right-15em-important {
    margin-right: 15em !important;
}

.hw-margin-right-20em-important {
    margin-right: 20em !important;
}

/* Margin-right PX */
.hw-margin-right-0px {
    margin-right: 0px;
}

.hw-margin-right-3px {
    margin-right: 3px;
}

.hw-margin-right-5px {
    margin-right: 5px;
}

.hw-margin-right-10px {
    margin-right: 10px;
}

.hw-margin-right-15px {
    margin-right: 15px;
}

.hw-margin-right-20px {
    margin-right: 20px;
}

/* Margin-right PX important */
.hw-margin-right-0px-important {
    margin-right: 0px !important;
}

.hw-margin-right-3px-important {
    margin-right: 3px !important;
}

.hw-margin-right-5px-important {
    margin-right: 5px !important;
}

.hw-margin-right-10px-important {
    margin-right: 10px !important;
}

.hw-margin-right-15px-important {
    margin-right: 15px !important;
}

.hw-margin-right-20px-important {
    margin-right: 20px !important;
}

/* Margin-bottom EM */
.hw-margin-bottom-0em {
    margin-bottom: 0em;
}

.hw-margin-bottom-05em {
    margin-bottom: 0.5em;
}

.hw-margin-bottom-1em {
    margin-bottom: 1em;
}

.hw-margin-bottom-2em {
    margin-bottom: 2em;
}

.hw-margin-bottom-3em {
    margin-bottom: 3em;
}

.hw-margin-bottom-4em {
    margin-bottom: 4em;
}

.hw-margin-bottom-5em {
    margin-bottom: 5em;
}

.hw-margin-bottom-6em {
    margin-bottom: 6em;
}

.hw-margin-bottom-7em {
    margin-bottom: 7em;
}

.hw-margin-bottom-8em {
    margin-bottom: 8em;
}

.hw-margin-bottom-9em {
    margin-bottom: 9em;
}

.hw-margin-bottom-10em {
    margin-bottom: 10em;
}

.hw-margin-bottom-15em {
    margin-bottom: 15em;
}

.hw-margin-bottom-20em {
    margin-bottom: 20em;
}

/* Margin-bottom EM important */
.hw-margin-bottom-0em-important {
    margin-bottom: 0em !important;
}

.hw-margin-bottom-05em-important {
    margin-bottom: 0.5em !important;
}

.hw-margin-bottom-1em-important {
    margin-bottom: 1em !important;
}

.hw-margin-bottom-2em-important {
    margin-bottom: 2em !important;
}

.hw-margin-bottom-3em-important {
    margin-bottom: 3em !important;
}

.hw-margin-bottom-4em-important {
    margin-bottom: 4em !important;
}

.hw-margin-bottom-5em-important {
    margin-bottom: 5em !important;
}

.hw-margin-bottom-6em-important {
    margin-bottom: 6em !important;
}

.hw-margin-bottom-7em-important {
    margin-bottom: 7em !important;
}

.hw-margin-bottom-8em-important {
    margin-bottom: 8em !important;
}

.hw-margin-bottom-9em-important {
    margin-bottom: 9em !important;
}

.hw-margin-bottom-10em-important {
    margin-bottom: 10em !important;
}

.hw-margin-bottom-15em-important {
    margin-bottom: 15em !important;
}

.hw-margin-bottom-20em-important {
    margin-bottom: 20em !important;
}

/* Margin-bottom EM negative */
.hw-margin-bottom--1em {
    margin-bottom: -1em;
}

.hw-margin-bottom--2em {
    margin-bottom: -2em;
}

.hw-margin-bottom--3em {
    margin-bottom: -3em;
}

.hw-margin-bottom--4em {
    margin-bottom: -4em;
}

.hw-margin-bottom--5em {
    margin-bottom: -5em;
}

.hw-margin-bottom--6em {
    margin-bottom: -6em;
}

.hw-margin-bottom--7em {
    margin-bottom: -7em;
}

.hw-margin-bottom--8em {
    margin-bottom: -8em;
}

.hw-margin-bottom--9em {
    margin-bottom: -9em;
}

.hw-margin-bottom--10em {
    margin-bottom: -10em;
}

.hw-margin-bottom--15em {
    margin-bottom: -15em;
}

.hw-margin-bottom--20em {
    margin-bottom: -20em;
}

/* Margin-bottom PX */
.hw-margin-bottom-0px {
    margin-bottom: 0px;
}

.hw-margin-bottom-3px {
    margin-bottom: 3px;
}

.hw-margin-bottom-5px {
    margin-bottom: 5px;
}

.hw-margin-bottom-10px {
    margin-bottom: 10px;
}

.hw-margin-bottom-15px {
    margin-bottom: 15px;
}

.hw-margin-bottom-20px {
    margin-bottom: 20px;
}

/* Margin-bottom PX important */
.hw-margin-bottom-0px-important {
    margin-bottom: 0px !important;
}

.hw-margin-bottom-3px-important {
    margin-bottom: 3px !important;
}

.hw-margin-bottom-5px-important {
    margin-bottom: 5px !important;
}

.hw-margin-bottom-10px-important {
    margin-bottom: 10px !important;
}

.hw-margin-bottom-15px-important {
    margin-bottom: 15px !important;
}

.hw-margin-bottom-20px-important {
    margin-bottom: 20px !important;
}

/* Margin-left EM */
.hw-margin-left-0em {
    margin-left: 0em;
}

.hw-margin-left-05em {
    margin-left: 0.5em;
}

.hw-margin-left-1em {
    margin-left: 1em;
}

.hw-margin-left-2em {
    margin-left: 2em;
}

.hw-margin-left-3em {
    margin-left: 3em;
}

.hw-margin-left-4em {
    margin-left: 4em;
}

.hw-margin-left-5em {
    margin-left: 5em;
}

.hw-margin-left-6em {
    margin-left: 6em;
}

.hw-margin-left-7em {
    margin-left: 7em;
}

.hw-margin-left-8em {
    margin-left: 8em;
}

.hw-margin-left-9em {
    margin-left: 9em;
}

.hw-margin-left-10em {
    margin-left: 10em;
}

.hw-margin-left-15em {
    margin-left: 15em;
}

.hw-margin-left-20em {
    margin-left: 20em;
}

/* Margin-left EM important */
.hw-margin-left-0em-important {
    margin-left: 0em !important;
}

.hw-margin-left-05em-important {
    margin-left: 0.5em !important;
}

.hw-margin-left-1em-important {
    margin-left: 1em !important;
}

.hw-margin-left-2em-important {
    margin-left: 2em !important;
}

.hw-margin-left-3em-important {
    margin-left: 3em !important;
}

.hw-margin-left-4em-important {
    margin-left: 4em !important;
}

.hw-margin-left-5em-important {
    margin-left: 5em !important;
}

.hw-margin-left-6em-important {
    margin-left: 6em !important;
}

.hw-margin-left-7em-important {
    margin-left: 7em !important;
}

.hw-margin-left-8em-important {
    margin-left: 8em !important;
}

.hw-margin-left-9em-important {
    margin-left: 9em !important;
}

.hw-margin-left-10em-important {
    margin-left: 10em !important;
}

.hw-margin-left-15em-important {
    margin-left: 15em !important;
}

.hw-margin-left-20em-important {
    margin-left: 20em !important;
}

/* Margin-left PX */
.hw-margin-left-0px {
    margin-left: 0px;
}

.hw-margin-left-3px {
    margin-left: 3px;
}

.hw-margin-left-5px {
    margin-left: 5px;
}

.hw-margin-left-10px {
    margin-left: 10px;
}

.hw-margin-left-15px {
    margin-left: 15px;
}

.hw-margin-left-20px {
    margin-left: 20px;
}

/* Margin-left PX important */
.hw-margin-left-0px-important {
    margin-left: 0px !important;
}

.hw-margin-left-3px-important {
    margin-left: 3px !important;
}

.hw-margin-left-5px-important {
    margin-left: 5px !important;
}

.hw-margin-left-10px-important {
    margin-left: 10px !important;
}

.hw-margin-left-15px-important {
    margin-left: 15px !important;
}

.hw-margin-left-20px-important {
    margin-left: 20px !important;
}

/* Visibility */
.hw-visibility-visible {
    visibility: visible;
}

.hw-visibility-invisible {
    visibility: hidden;
}

/* Visibility important */
.hw-visibility-visible-important {
    visibility: visible !important;
}

.hw-visibility-invisible-important {
    visibility: hidden !important;
}

/* Object-fit */
.hw-object-contain {
    object-fit: contain;
}

.hw-object-cover {
    object-fit: cover;
}

.hw-object-fill {
    object-fit: fill;
}

.hw-object-none {
    object-fit: none;
}

.hw-object-scale-down {
    object-fit: scale-down;
}

/* Object-fit important */
.hw-object-contain-important {
    object-fit: contain !important;
}

.hw-object-cover-important {
    object-fit: cover !important;
}

.hw-object-fill-important {
    object-fit: fill !important;
}

.hw-object-none-important {
    object-fit: none !important;
}

.hw-object-scale-down-important {
    object-fit: scale-down !important;
}

/* Object position */
.hw-object-bottom {
    object-position: bottom;
}

.hw-object-center {
    object-position: center;
}

.hw-object-left {
    object-position: left;
}

.hw-object-left-bottom {
    object-position: left bottom;
}

.hw-object-left-top {
    object-position: left top;
}

.hw-object-right {
    object-position: right;
}

.hw-object-right-bottom {
    object-position: right bottom;
}

.hw-object-right-top {
    object-position: right top;
}

.hw-object-top {
    object-position: top;
}

/* Object position important */
.hw-object-bottom-important {
    object-position: bottom !important;
}

.hw-object-center-important {
    object-position: center !important;
}

.hw-object-left-important {
    object-position: left !important;
}

.hw-object-left-bottom-important {
    object-position: left bottom !important;
}

.hw-object-left-top-important {
    object-position: left top !important;
}

.hw-object-right-important {
    object-position: right !important;
}

.hw-object-right-bottom-important {
    object-position: right bottom !important;
}

.hw-object-right-top-important {
    object-position: right top !important;
}

.hw-object-top-important {
    object-position: top !important;
}

/* Position */
.hw-position-static {
    position: static;
}

.hw-position-fixed {
    position: fixed;
}

.hw-position-absolute {
    position: absolute;
}

.hw-position-relative {
    position: relative;
}

.hw-position-sticky {
    position: sticky;
}

/* Position important */
.hw-position-static-important {
    position: static !important;
}

.hw-position-fixed-important {
    position: fixed !important;
}

.hw-position-absolute-important {
    position: absolute !important;
}

.hw-position-relative-important {
    position: relative !important;
}

.hw-position-sticky-important {
    position: sticky !important;
}

/* Z-index */
.hw-zindex-0 {
    z-index: 0;
}

.hw-zindex-10 {
    z-index: 10;
}

.hw-zindex-20 {
    z-index: 20;
}

.hw-zindex-30 {
    z-index: 30;
}

.hw-zindex-40 {
    z-index: 40;
}

.hw-zindex-50 {
    z-index: 50;
}

.hw-zindex-100 {
    z-index: 100;
}

.hw-zindex-200 {
    z-index: 200;
}

.hw-zindex-300 {
    z-index: 300;
}

.hw-zindex-400 {
    z-index: 400;
}

.hw-zindex-500 {
    z-index: 500;
}

.hw-zindex-600 {
    z-index: 600;
}

.hw-zindex-700 {
    z-index: 700;
}

.hw-zindex-800 {
    z-index: 800;
}

.hw-zindex-900 {
    z-index: 900;
}

.hw-zindex-1000 {
    z-index: 1000;
}

.hw-zindex-2000 {
    z-index: 2000;
}

.hw-zindex-auto {
    z-index: auto;
}

/* Z-index important */
.hw-zindex-0-important {
    z-index: 0 !important;
}

.hw-zindex-10-important {
    z-index: 10 !important;
}

.hw-zindex-20-important {
    z-index: 20 !important;
}

.hw-zindex-30-important {
    z-index: 30 !important;
}

.hw-zindex-40-important {
    z-index: 40 !important;
}

.hw-zindex-50-important {
    z-index: 50 !important;
}

.hw-zindex-100-important {
    z-index: 100 !important;
}

.hw-zindex-200-important {
    z-index: 200 !important;
}

.hw-zindex-300-important {
    z-index: 300 !important;
}

.hw-zindex-400-important {
    z-index: 400 !important;
}

.hw-zindex-500-important {
    z-index: 500 !important;
}

.hw-zindex-600-important {
    z-index: 600 !important;
}

.hw-zindex-700-important {
    z-index: 700 !important;
}

.hw-zindex-800-important {
    z-index: 800 !important;
}

.hw-zindex-900-important {
    z-index: 900 !important;
}

.hw-zindex-1000-important {
    z-index: 1000 !important;
}

.hw-zindex-2000-important {
    z-index: 2000 !important;
}

.hw-zindex-auto-important {
    z-index: auto !important;
}

/* Flex direction */
.hw-flex-row {
    flex-direction: row;
}

.hw-flex-row-reverse {
    flex-direction: row-reverse;
}

.hw-flex-col {
    flex-direction: column;
}

.hw-flex-col-reverse {
    flex-direction: column-reverse;
}

/* Flex direction important */
.hw-flex-row-important {
    flex-direction: row !important;
}

.hw-flex-row-reverse-important {
    flex-direction: row-reverse !important;
}

.hw-flex-col-important {
    flex-direction: column !important;
}

.hw-flex-col-reverse-important {
    flex-direction: column-reverse !important;
}

/* Flex wrap */
.hw-flex-wrap {
    flex-wrap: wrap;
}

.hw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.hw-flex-nowrap {
    flex-wrap: nowrap;
}

/* Flex wrap important*/
.hw-flex-wrap-important {
    flex-wrap: wrap !important;
}

.hw-flex-wrap-reverse-important {
    flex-wrap: wrap-reverse !important;
}

.hw-flex-nowrap-important {
    flex-wrap: nowrap !important;
}

/* Flex order */
.hw-flex-order-1 {
    order: 1;
}

.hw-flex-order-2 {
    order: 2;
}

.hw-flex-order-3 {
    order: 3;
}

.hw-flex-order-4 {
    order: 4;
}

.hw-flex-order-5 {
    order: 5;
}

.hw-flex-order-6 {
    order: 6;
}

.hw-flex-order-7 {
    order: 7;
}

.hw-flex-order-8 {
    order: 8;
}

.hw-flex-order-9 {
    order: 9;
}

.hw-flex-order-10 {
    order: 10;
}

.hw-flex-order-11 {
    order: 11;
}

.hw-flex-order-12 {
    order: 12;
}

.hw-flex-order-first {
    order: -9999;
}

.hw-flex-order-last {
    order: 9999;
}

.hw-flex-order-none {
    order: 0;
}

/* Justify content */
.hw-justify-content-start {
    justify-content: flex-start;
}

.hw-justify-content-end {
    justify-content: flex-end;
}

.hw-justify-content-center {
    justify-content: center;
}

.hw-justify-content-between {
    justify-content: space-between;
}

.hw-justify-content-around {
    justify-content: space-around;
}

.hw-justify-content-evenly {
    justify-content: space-evenly;
}

/* Justify content important */
.hw-justify-content-start-important {
    justify-content: flex-start !important;
}

.hw-justify-content-end-important {
    justify-content: flex-end !important;
}

.hw-justify-content-center-important {
    justify-content: center !important;
}

.hw-justify-content-between-important {
    justify-content: space-between !important;
}

.hw-justify-content-around-important {
    justify-content: space-around !important;
}

.hw-justify-content-evenly-important {
    justify-content: space-evenly !important;
}

/* Justify items */
.hw-justify-items-start {
    justify-items: start;
}

.hw-justify-items-end {
    justify-items: end;
}

.hw-justify-items-center {
    justify-items: center;
}

.hw-justify-items-stretch {
    justify-items: stretch;
}

/* Justify items important */
.hw-justify-items-start-important {
    justify-items: start !important;
}

.hw-justify-items-end-important {
    justify-items: end !important;
}

.hw-justify-items-center-important {
    justify-items: center !important;
}

.hw-justify-items-stretch-important {
    justify-items: stretch !important;
}

/* Align content */
.hw-content-center {
    align-content: center;
}

.hw-content-start {
    align-content: flex-start;
}

.hw-content-end {
    align-content: flex-end;
}

.hw-content-between {
    align-content: space-between;
}

.hw-content-around {
    align-content: space-around;
}

.hw-content-evenly {
    align-content: space-evenly;
}

/* Align content important */
.hw-content-center-important {
    align-content: center !important;
}

.hw-content-start-important {
    align-content: flex-start !important;
}

.hw-content-end-important {
    align-content: flex-end !important;
}

.hw-content-between-important {
    align-content: space-between !important;
}

.hw-content-around-important {
    align-content: space-around !important;
}

.hw-content-evenly-important {
    align-content: space-evenly !important;
}

/* Align items */
.hw-align-items-start {
    align-items: flex-start;
}

.hw-align-items-end {
    align-items: flex-end;
}

.hw-align-items-center {
    align-items: center;
}

.hw-align-items-baseline {
    align-items: baseline;
}

.hw-align-items-stretch {
    align-items: stretch;
}

.hw-vertical-align {
    vertical-align: middle;
}

/* Align items important */
.hw-align-items-start-important {
    align-items: flex-start !important;
}

.hw-align-items-end-important {
    align-items: flex-end !important;
}

.hw-align-items-center-important {
    align-items: center !important;
}

.hw-align-items-baseline-important {
    align-items: baseline !important;
}

.hw-align-items-stretch-important {
    align-items: stretch !important;
}

/* Font weight */
.hw-font-weight-100 {
    font-weight: 100;
}

.hw-font-weight-200 {
    font-weight: 200;
}

.hw-font-weight-300 {
    font-weight: 300;
}

.hw-font-weight-400 {
    font-weight: 400;
}

.hw-font-weight-500 {
    font-weight: 500;
}

.hw-font-weight-600 {
    font-weight: 600;
}

.hw-font-weight-700 {
    font-weight: 700;
}

.hw-font-weight-800 {
    font-weight: 800;
}

.hw-font-weight-900 {
    font-weight: 900;
}

/* Font weight important */
.hw-font-weight-100-important {
    font-weight: 100 !important;
}

.hw-font-weight-200-important {
    font-weight: 200 !important;
}

.hw-font-weight-300-important {
    font-weight: 300 !important;
}

.hw-font-weight-400-important {
    font-weight: 400 !important;
}

.hw-font-weight-500-important {
    font-weight: 500 !important;
}

.hw-font-weight-600-important {
    font-weight: 600 !important;
}

.hw-font-weight-700-important {
    font-weight: 700 !important;
}

.hw-font-weight-800-important {
    font-weight: 800 !important;
}

.hw-font-weight-900-important {
    font-weight: 900 !important;
}

/* Font size */
.hw-font-size-10 {
    font-size: 10px;
}

.hw-font-size-11 {
    font-size: 11px;
}

.hw-font-size-12 {
    font-size: 12px;
}

.hw-font-size-13 {
    font-size: 13px;
}

.hw-font-size-14 {
    font-size: 14px;
}

.hw-font-size-15 {
    font-size: 15px;
}

.hw-font-size-16 {
    font-size: 16px;
}

.hw-font-size-17 {
    font-size: 17px;
}

.hw-font-size-18 {
    font-size: 18px;
}

.hw-font-size-19 {
    font-size: 19px;
}

.hw-font-size-20 {
    font-size: 20px;
}

.hw-font-size-21 {
    font-size: 21px;
}

.hw-font-size-22 {
    font-size: 22px;
}

.hw-font-size-23 {
    font-size: 23px;
}

.hw-font-size-24 {
    font-size: 24px;
}

.hw-font-size-25 {
    font-size: 25px;
}

.hw-font-size-26 {
    font-size: 26px;
}

.hw-font-size-27 {
    font-size: 27px;
}

.hw-font-size-28 {
    font-size: 28px;
}

.hw-font-size-29 {
    font-size: 29px;
}

.hw-font-size-30 {
    font-size: 30px;
}

.hw-font-size-40 {
    font-size: 40px;
}

.hw-font-size-50 {
    font-size: 50px;
}

.hw-font-size-60 {
    font-size: 60px;
}

.hw-font-size-70 {
    font-size: 70px;
}

.hw-font-size-80 {
    font-size: 80px;
}

.hw-font-size-90 {
    font-size: 90px;
}

.hw-font-size-100 {
    font-size: 100px;
}

/* Font size imporatnt */
.hw-font-size-10-important {
    font-size: 10px !important;
}

.hw-font-size-11-important {
    font-size: 11px !important;
}

.hw-font-size-12-important {
    font-size: 12px !important;
}

.hw-font-size-13-important {
    font-size: 13px !important;
}

.hw-font-size-14-important {
    font-size: 14px !important;
}

.hw-font-size-15-important {
    font-size: 15px !important;
}

.hw-font-size-16-important {
    font-size: 16px !important;
}

.hw-font-size-17-important {
    font-size: 17px !important;
}

.hw-font-size-18-important {
    font-size: 18px !important;
}

.hw-font-size-19-important {
    font-size: 19px !important;
}

.hw-font-size-20-important {
    font-size: 20px !important;
}

.hw-font-size-21-important {
    font-size: 21px !important;
}

.hw-font-size-22-important {
    font-size: 22px !important;
}

.hw-font-size-23-important {
    font-size: 23px !important;
}

.hw-font-size-24-important {
    font-size: 24px !important;
}

.hw-font-size-25-important {
    font-size: 25px !important;
}

.hw-font-size-26-important {
    font-size: 26px !important;
}

.hw-font-size-27-important {
    font-size: 27px !important;
}

.hw-font-size-28-important {
    font-size: 28px !important;
}

.hw-font-size-29-important {
    font-size: 29px !important;
}

.hw-font-size-30-important {
    font-size: 30px !important;
}

.hw-font-size-40-important {
    font-size: 40px !important;
}

.hw-font-size-50-important {
    font-size: 50px !important;
}

.hw-font-size-60-important {
    font-size: 60px !important;
}

.hw-font-size-70-important {
    font-size: 70px !important;
}

.hw-font-size-80-important {
    font-size: 80px !important;
}

.hw-font-size-90-important {
    font-size: 90px !important;
}

.hw-font-size-100-important {
    font-size: 100px !important;
}

/* Text align */
.hw-text-align-left {
    text-align: left;
}

.hw-text-align-center {
    text-align: center;
}

.hw-text-align-right {
    text-align: right;
}

.hw-text-align-justify {
    text-align: justify;
}

.hw-text-align-start {
    text-align: start;
}

.hw-text-align-end {
    text-align: end;
}

/* Text align important */
.hw-text-align-left-important {
    text-align: left !important;
}

.hw-text-align-center-important {
    text-align: center !important;
}

.hw-text-align-right-important {
    text-align: right !important;
}

.hw-text-align-justify-important {
    text-align: justify !important;
}

.hw-text-align-start-important {
    text-align: start !important;
}

.hw-text-align-end-important {
    text-align: end !important;
}

/* Text decoration */
.hw-underline {
    text-decoration-line: underline;
}

.hw-overline {
    text-decoration-line: overline;
}

.hw-line-through {
    text-decoration-line: line-through;
}

.hw-no-underline {
    text-decoration-line: none;
}

/* Text decoration important */
.hw-underline-important {
    text-decoration-line: underline !important;
}

.hw-overline-important {
    text-decoration-line: overline !important;
}

.hw-line-through-important {
    text-decoration-line: line-through !important;
}

.hw-no-underline-important {
    text-decoration-line: none !important;
}

/* Text transform */
.hw-text-uppercase {
    text-transform: uppercase;
}

.hw-text-lowercase {
    text-transform: lowercase;
}

.hw-text-capitalize {
    text-transform: capitalize;
}

.hw-text-normal-case {
    text-transform: none;
}

/* Text transform important */
.hw-text-uppercase-important {
    text-transform: uppercase !important;
}

.hw-text-lowercase-important {
    text-transform: lowercase !important;
}

.hw-text-capitalize-important {
    text-transform: capitalize !important;
}

.hw-text-normal-case-important {
    text-transform: none !important;
}

/* Whitespace */
.hw-whitespace-normal {
    white-space: normal;
}

.hw-whitespace-nowrap {
    white-space: nowrap;
}

.hw-whitespace-pre {
    white-space: pre;
}

.hw-whitespace-pre-line {
    white-space: pre-line;
}

.hw-whitespace-pre-wrap {
    white-space: pre-wrap;
}

/* Whitespace important */
.hw-whitespace-normal-important {
    white-space: normal !important;
}

.hw-whitespace-nowrap-important {
    white-space: nowrap !important;
}

.hw-whitespace-pre-important {
    white-space: pre !important;
}

.hw-whitespace-pre-line-important {
    white-space: pre-line !important;
}

.hw-whitespace-pre-wrap-important {
    white-space: pre-wrap !important;
}

/* Border radius */
.hw-border-radius-0px {
    border-radius: 0px;
}

.hw-border-radius-5px {
    border-radius: 5px;
}

.hw-border-radius-10px {
    border-radius: 10px;
}

.hw-border-radius-15px {
    border-radius: 15px;
}

.hw-border-radius-20px {
    border-radius: 20px;
}

.hw-border-radius-25px {
    border-radius: 25px;
}

.hw-border-radius-30px {
    border-radius: 30px;
}

.hw-border-radius-35px {
    border-radius: 35px;
}

.hw-border-radius-40px {
    border-radius: 40px;
}

.hw-border-radius-45px {
    border-radius: 45px;
}

.hw-border-radius-50px {
    border-radius: 50px;
}

.hw-border-sm {
    border-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded {
    border-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-md {
    border-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-lg {
    border-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-xl {
    border-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-2xl {
    border-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-3xl {
    border-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-full {
    border-radius: 9999px;
}

.hw-border-rounded-t-none {
    border-top-left-radius: 0px;
}

.hw-border-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded-t {
    border-top-left-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-t-md {
    border-top-left-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-t-2xl {
    border-top-left-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-t-full {
    border-top-left-radius: 9999px;
}

.hw-border-rounded-r-none {
    border-top-right-radius: 0px;
}

.hw-border-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded-r {
    border-top-right-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-r-md {
    border-top-right-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-r-2xl {
    border-top-right-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-r-full {
    border-top-right-radius: 9999px;
}

.hw-border-rounded-b-none {
    border-bottom-right-radius: 0px;
}

.hw-border-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded-b {
    border-bottom-right-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-b-full {
    border-bottom-right-radius: 9999px;
}

.hw-border-rounded-l-none {
    border-top-left-radius: 0px;
}

.hw-border-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded-l {
    border-top-left-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-l-md {
    border-top-left-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-l-2xl {
    border-top-left-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-l-full {
    border-top-left-radius: 9999px;
}

.hw-border-rounded-tl-none {
    border-top-left-radius: 0px;
}

.hw-border-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded-tl {
    border-top-left-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-tl-md {
    border-top-left-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-tl-2xl {
    border-top-left-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-tl-full {
    border-top-left-radius: 9999px;
}

.hw-border-rounded-tr-none {
    border-top-right-radius: 0px;
}

.hw-border-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded-tr {
    border-top-right-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-tr-md {
    border-top-right-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-tr-2xl {
    border-top-right-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-tr-full {
    border-top-right-radius: 9999px;
}

.hw-border-rounded-br-none {
    border-bottom-right-radius: 0px;
}

.hw-border-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded-br {
    border-bottom-right-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-br-full {
    border-bottom-right-radius: 9999px;
}

.hw-border-rounded-bl-none {
    border-bottom-left-radius: 0px;
}

.hw-border-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
    /* 2px */
}

.hw-border-rounded-bl {
    border-bottom-left-radius: 0.25rem;
    /* 4px */
}

.hw-border-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
    /* 6px */
}

.hw-border-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
    /* 8px */
}

.hw-border-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
    /* 12px */
}

.hw-border-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
    /* 16px */
}

.hw-border-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
    /* 24px */
}

.hw-border-rounded-bl-full {
    border-bottom-left-radius: 9999px;
}

/* Border radius !important */
.hw-border-radius-0px-important {
    border-radius: 0px !important;
}

.hw-border-radius-5px-important {
    border-radius: 5px !important;
}

.hw-border-radius-10px-important {
    border-radius: 10px !important;
}

.hw-border-radius-15px-important {
    border-radius: 15px !important;
}

.hw-border-radius-20px-important {
    border-radius: 20px !important;
}

.hw-border-radius-25px-important {
    border-radius: 25px !important;
}

.hw-border-radius-30px-important {
    border-radius: 30px !important;
}

.hw-border-radius-35px-important {
    border-radius: 35px !important;
}

.hw-border-radius-40px-important {
    border-radius: 40px !important;
}

.hw-border-radius-45px-important {
    border-radius: 45px !important;
}

.hw-border-radius-50px-important {
    border-radius: 50px !important;
}

/* Border color */
.hw-border-inherit {
    border-color: inherit;
}

.hw-border-current {
    border-color: currentColor;
}

.hw-border-transparent {
    border-color: transparent;
}

.hw-border-black {
    border-color: rgb(0 0 0);
}

.hw-border-white {
    border-color: rgb(255 255 255);
}

/* Shadow */
.hw-shadow-sm {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.hw-shadow {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.hw-shadow-md {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.hw-shadow-lg {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.hw-shadow-xl {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.hw-shadow-2xl {
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.hw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

.hw-shadow-none {
    box-shadow: 0 0 #0000;
}

/* Opacity */
.hw-opacity-0 {
    opacity: 0;
}

.hw-opacity-5 {
    opacity: 0.05;
}

.hw-opacity-10 {
    opacity: 0.1;
}

.hw-opacity-20 {
    opacity: 0.2;
}

.hw-opacity-25 {
    opacity: 0.25;
}

.hw-opacity-30 {
    opacity: 0.3;
}

.hw-opacity-40 {
    opacity: 0.4;
}

.hw-opacity-50 {
    opacity: 0.5;
}

.hw-opacity-60 {
    opacity: 0.6;
}

.hw-opacity-70 {
    opacity: 0.7;
}

.hw-opacity-75 {
    opacity: 0.75;
}

.hw-opacity-80 {
    opacity: 0.8;
}

.hw-opacity-90 {
    opacity: 0.9;
}

.hw-opacity-95 {
    opacity: 0.95;
}

.hw-opacity-100 {
    opacity: 1;
}

/* Opacity important */
.hw-opacity-0-important {
    opacity: 0 !important;
}

.hw-opacity-5-important {
    opacity: 0.05 !important;
}

.hw-opacity-10-important {
    opacity: 0.1 !important;
}

.hw-opacity-20-important {
    opacity: 0.2 !important;
}

.hw-opacity-25-important {
    opacity: 0.25 !important;
}

.hw-opacity-30-important {
    opacity: 0.3 !important;
}

.hw-opacity-40-important {
    opacity: 0.4 !important;
}

.hw-opacity-50-important {
    opacity: 0.5 !important;
}

.hw-opacity-60-important {
    opacity: 0.6 !important;
}

.hw-opacity-70-important {
    opacity: 0.7 !important;
}

.hw-opacity-75-important {
    opacity: 0.75 !important;
}

.hw-opacity-80-important {
    opacity: 0.8 !important;
}

.hw-opacity-90-important {
    opacity: 0.9 !important;
}

.hw-opacity-95-important {
    opacity: 0.95 !important;
}

.hw-opacity-100-important {
    opacity: 1 !important;
}

/* Blur */
.hw-blur-none {
    filter: blur(0);
}

.hw-blur-sm {
    filter: blur(4px);
}

.hw-blur {
    filter: blur(8px);
}

.hw-blur-md {
    filter: blur(12px);
}

.hw-blur-lg {
    filter: blur(16px);
}

.hw-blur-xl {
    filter: blur(24px);
}

.hw-blur-2xl {
    filter: blur(40px);
}

.hw-blur-3xl {
    filter: blur(64px);
}

/* Drop shadow */
.hw-drop-shadow-sm {
    filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
}

.hw-drop-shadow {
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
}

.hw-drop-shadow-md {
    filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.hw-drop-shadow-lg {
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.hw-drop-shadow-xl {
    filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
}

.hw-drop-shadow-2xl {
    filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.hw-drop-shadow-none {
    filter: drop-shadow(0 0 #0000);
}

/* Grayscale */
.hw-grayscale-0 {
    filter: grayscale(0);
}

.hw-grayscale {
    filter: grayscale(100%);
}

/* Table layout */
.hw-table-auto {
    table-layout: auto;
}

.hw-table-fixed {
    table-layout: fixed;
}

/* Table layout important */
.hw-table-auto-important {
    table-layout: auto !important;
}

.hw-table-fixed-important {
    table-layout: fixed !important;
}

/* Cursor */
.hw-cursor-auto {
    cursor: auto;
}

.hw-cursor-default {
    cursor: default;
}

.hw-cursor-pointer {
    cursor: pointer;
}

.hw-cursor-wait {
    cursor: wait;
}

.hw-cursor-text {
    cursor: text;
}

.hw-cursor-move {
    cursor: move;
}

.hw-cursor-help {
    cursor: help;
}

.hw-cursor-not-allowed {
    cursor: not-allowed;
}

.hw-cursor-none {
    cursor: none;
}

.hw-cursor-context-menu {
    cursor: context-menu;
}

.hw-cursor-progress {
    cursor: progress;
}

.hw-cursor-cell {
    cursor: cell;
}

.hw-cursor-crosshair {
    cursor: crosshair;
}

.hw-cursor-vertical-text {
    cursor: vertical-text;
}

.hw-cursor-alias {
    cursor: alias;
}

.hw-cursor-copy {
    cursor: copy;
}

.hw-cursor-no-drop {
    cursor: no-drop;
}

.hw-cursor-grab {
    cursor: grab;
}

.hw-cursor-grabbing {
    cursor: grabbing;
}

.hw-cursor-all-scroll {
    cursor: all-scroll;
}

.hw-cursor-col-resize {
    cursor: col-resize;
}

.hw-cursor-row-resize {
    cursor: row-resize;
}

.hw-cursor-n-resize {
    cursor: n-resize;
}

.hw-cursor-e-resize {
    cursor: e-resize;
}

.hw-cursor-s-resize {
    cursor: s-resize;
}

.hw-cursor-w-resize {
    cursor: w-resize;
}

.hw-cursor-ne-resize {
    cursor: ne-resize;
}

.hw-cursor-nw-resize {
    cursor: nw-resize;
}

.hw-cursor-se-resize {
    cursor: se-resize;
}

.hw-cursor-sw-resize {
    cursor: sw-resize;
}

.hw-cursor-ew-resize {
    cursor: ew-resize;
}

.hw-cursor-ns-resize {
    cursor: ns-resize;
}

.hw-cursor-nesw-resize {
    cursor: nesw-resize;
}

.hw-cursor-nwse-resize {
    cursor: nwse-resize;
}

.hw-cursor-zoom-in {
    cursor: zoom-in;
}

.hw-cursor-zoom-out {
    cursor: zoom-out;
}

/* Cursor important */
.hw-cursor-auto-important {
    cursor: auto !important;
}

.hw-cursor-default-important {
    cursor: default !important;
}

.hw-cursor-pointer-important {
    cursor: pointer !important;
}

.hw-cursor-wait-important {
    cursor: wait !important;
}

.hw-cursor-text-important {
    cursor: text !important;
}

.hw-cursor-move-important {
    cursor: move !important;
}

.hw-cursor-help-important {
    cursor: help !important;
}

.hw-cursor-not-allowed-important {
    cursor: not-allowed !important;
}

.hw-cursor-none-important {
    cursor: none !important;
}

.hw-cursor-context-menu-important {
    cursor: context-menu !important;
}

.hw-cursor-progress-important {
    cursor: progress !important;
}

.hw-cursor-cell-important {
    cursor: cell !important;
}

.hw-cursor-crosshair-important {
    cursor: crosshair !important;
}

.hw-cursor-vertical-text-important {
    cursor: vertical-text !important;
}

.hw-cursor-alias-important {
    cursor: alias !important;
}

.hw-cursor-copy-important {
    cursor: copy !important;
}

.hw-cursor-no-drop-important {
    cursor: no-drop !important;
}

.hw-cursor-grab-important {
    cursor: grab !important;
}

.hw-cursor-grabbing-important {
    cursor: grabbing !important;
}

.hw-cursor-all-scroll-important {
    cursor: all-scroll !important;
}

.hw-cursor-col-resize-important {
    cursor: col-resize !important;
}

.hw-cursor-row-resize-important {
    cursor: row-resize !important;
}

.hw-cursor-n-resize-important {
    cursor: n-resize !important;
}

.hw-cursor-e-resize-important {
    cursor: e-resize !important;
}

.hw-cursor-s-resize-important {
    cursor: s-resize !important;
}

.hw-cursor-w-resize-important {
    cursor: w-resize !important;
}

.hw-cursor-ne-resize-important {
    cursor: ne-resize !important;
}

.hw-cursor-nw-resize-important {
    cursor: nw-resize !important;
}

.hw-cursor-se-resize-important {
    cursor: se-resize !important;
}

.hw-cursor-sw-resize-important {
    cursor: sw-resize !important;
}

.hw-cursor-ew-resize-important {
    cursor: ew-resize !important;
}

.hw-cursor-ns-resize-important {
    cursor: ns-resize !important;
}

.hw-cursor-nesw-resize-important {
    cursor: nesw-resize !important;
}

.hw-cursor-nwse-resize-important {
    cursor: nwse-resize !important;
}

.hw-cursor-zoom-in-important {
    cursor: zoom-in !important;
}

.hw-cursor-zoom-out-important {
    cursor: zoom-out !important;
}

/* Scroll Behavior */
.hw-scroll-auto {
    scroll-behavior: auto;
}

.hw-scroll-smooth {
    scroll-behavior: smooth;
}

/* Scroll Behavior important */
.hw-scroll-auto-important {
    scroll-behavior: auto !important;
}

.hw-scroll-smooth-important {
    scroll-behavior: smooth !important;
}

/* Width */
.hw-width-25 {
    width: 25%;
}

.hw-width-50 {
    width: 50%;
}

.hw-width-75 {
    width: 75%;
}

.hw-width-99 {
    width: 99%;
}

.hw-width-100 {
    width: 100%;
}

.hw-25 {
    width: 25%;
}

.hw-50 {
    width: 50%;
}

.hw-75 {
    width: 75%;
}

.hw-100 {
    width: 100%;
}

/* Width Important*/
.hw-width-25-important {
    width: 25% !important;
}

.hw-width-50-important {
    width: 50% !important;
}

.hw-width-75-important {
    width: 75% !important;
}

.hw-width-99-important {
    width: 99% !important;
}

.hw-width-100-important {
    width: 100% !important;
}

.hw-25-important {
    width: 25% !important;
}

.hw-50-important {
    width: 50% !important;
}

.hw-75-important {
    width: 75% !important;
}

.hw-100-important {
    width: 100% !important;
}

/* Virtual height */
.hw-height-5vh {
    height: 5vh;
}

.hw-height-10vh {
    height: 10vh;
}

.hw-height-15vh {
    height: 15vh;
}

.hw-height-20vh {
    height: 20vh;
}

.hw-height-25vh {
    height: 25vh;
}

.hw-height-30vh {
    height: 30vh;
}

.hw-height-35vh {
    height: 35vh;
}

.hw-height-40vh {
    height: 40vh;
}

.hw-height-45vh {
    height: 45vh;
}

.hw-height-50vh {
    height: 50vh;
}

.hw-height-55vh {
    height: 55vh;
}

.hw-height-60vh {
    height: 60vh;
}

.hw-height-65vh {
    height: 65vh;
}

.hw-height-70vh {
    height: 70vh;
}

.hw-height-75vh {
    height: 75vh;
}

.hw-height-80vh {
    height: 80vh;
}

.hw-height-85vh {
    height: 85vh;
}

.hw-height-90vh {
    height: 90vh;
}

.hw-height-95vh {
    height: 95vh;
}

.hw-height-100vh {
    height: 100vh;
}

/* Virtual height - Important */
.hw-height-5vh-important {
    height: 5vh !important;
}

.hw-height-10vh-important {
    height: 10vh !important;
}

.hw-height-15vh-important {
    height: 15vh !important;
}

.hw-height-20vh-important {
    height: 20vh !important;
}

.hw-height-25vh-important {
    height: 25vh !important;
}

.hw-height-30vh-important {
    height: 30vh !important;
}

.hw-height-35vh-important {
    height: 35vh !important;
}

.hw-height-40vh-important {
    height: 40vh !important;
}

.hw-height-45vh-important {
    height: 45vh !important;
}

.hw-height-50vh-important {
    height: 50vh !important;
}

.hw-height-55vh-important {
    height: 55vh !important;
}

.hw-height-60vh-important {
    height: 60vh !important;
}

.hw-height-65vh-important {
    height: 65vh !important;
}

.hw-height-70vh-important {
    height: 70vh !important;
}

.hw-height-75vh-important {
    height: 75vh !important;
}

.hw-height-80vh-important {
    height: 80vh !important;
}

.hw-height-85vh-important {
    height: 85vh !important;
}

.hw-height-90vh-important {
    height: 90vh !important;
}

.hw-height-95vh-important {
    height: 95vh !important;
}

.hw-height-100vh-important {
    height: 100vh !important;
}

/* Virtual width */
.hw-width-5vh {
    width: 5vh;
}

.hw-width-10vh {
    width: 10vh;
}

.hw-width-15vh {
    width: 15vh;
}

.hw-width-20vh {
    width: 20vh;
}

.hw-width-25vh {
    width: 25vh;
}

.hw-width-30vh {
    width: 30vh;
}

.hw-width-35vh {
    width: 35vh;
}

.hw-width-40vh {
    width: 40vh;
}

.hw-width-45vh {
    width: 45vh;
}

.hw-width-50vh {
    width: 50vh;
}

.hw-width-55vh {
    width: 55vh;
}

.hw-width-60vh {
    width: 60vh;
}

.hw-width-65vh {
    width: 65vh;
}

.hw-width-70vh {
    width: 70vh;
}

.hw-width-75vh {
    width: 75vh;
}

.hw-width-80vh {
    width: 80vh;
}

.hw-width-85vh {
    width: 85vh;
}

.hw-width-90vh {
    width: 90vh;
}

.hw-width-95vh {
    width: 95vh;
}

.hw-width-100vh-important {
    width: 100vh;
}

/* Virtual width - Important */
.hw-width-5vh-important {
    width: 5vh !important;
}

.hw-width-10vh-important {
    width: 10vh !important;
}

.hw-width-15vh-important {
    width: 15vh !important;
}

.hw-width-20vh-important {
    width: 20vh !important;
}

.hw-width-25vh-important {
    width: 25vh !important;
}

.hw-width-30vh-important {
    width: 30vh !important;
}

.hw-width-35vh-important {
    width: 35vh !important;
}

.hw-width-40vh-important {
    width: 40vh !important;
}

.hw-width-45vh-important {
    width: 45vh !important;
}

.hw-width-50vh-important {
    width: 50vh !important;
}

.hw-width-55vh-important {
    width: 55vh !important;
}

.hw-width-60vh-important {
    width: 60vh !important;
}

.hw-width-65vh-important {
    width: 65vh !important;
}

.hw-width-70vh-important {
    width: 70vh !important;
}

.hw-width-75vh-important {
    width: 75vh !important;
}

.hw-width-80vh-important {
    width: 80vh !important;
}

.hw-width-85vh-important {
    width: 85vh !important;
}

.hw-width-90vh-important {
    width: 90vh !important;
}

.hw-width-95vh-important {
    width: 95vh !important;
}

.hw-width-100vh-important {
    width: 100vh !important;
}

/* Line Height - EM */
.hw-line-height-1em {
    line-height: 1em;
}

.hw-line-height-2em {
    line-height: 2em;
}

.hw-line-height-3em {
    line-height: 3em;
}

.hw-line-height-4em {
    line-height: 4em;
}

.hw-line-height-5em {
    line-height: 5em;
}

.hw-line-height-6em {
    line-height: 6em;
}

.hw-line-height-7em {
    line-height: 7em;
}

.hw-line-height-8em {
    line-height: 8em;
}

.hw-line-height-9em {
    line-height: 9em;
}

.hw-line-height-10em {
    line-height: 10em;
}

/* Line Height - EM - Important*/
.hw-line-height-1em-important {
    line-height: 1em;
}

.hw-line-height-2em-important {
    line-height: 2em !important;
}

.hw-line-height-3em-important {
    line-height: 3em !important;
}

.hw-line-height-4em-important {
    line-height: 4em !important;
}

.hw-line-height-5em-important {
    line-height: 5em !important;
}

.hw-line-height-6em-important {
    line-height: 6em !important;
}

.hw-line-height-7em-important {
    line-height: 7em !important;
}

.hw-line-height-8em-important {
    line-height: 8em !important;
}

.hw-line-height-9em-important {
    line-height: 9em !important;
}

.hw-line-height-10em-important {
    line-height: 10em !important;
}

/* Divider Line */
.hw-vertical-divider {
    width: 4px;
    margin: 6px 0;
    background: #03a9f4;
}

.hw-horizontal-divider {
    display: flex;
    background: #03a9f4;
    height: 2px;
}

/* Material buttons */
.mat-green-button {
    padding: 0 6px 0 6px;
    margin: 6px 8px 6px 8px;
    min-width: 100px;
    border-radius: 0px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    outline: none;
    color: green;
}

/* mat-checkbox vertical align */
.hw-checkbox-vertical-align {
    margin-top: 20px;
}

/* table mobile */
@media (max-width: 599px) {
    .hw-mobile-table-title-hide {
        display: none;
    }

    .hw-mobile-table-title-show {
        display: inline-block;
        padding: 0px 20px 12px 0px;
    }

    .hw-mobile-display-inherit {
        display: inherit;
    }

    .hw-mobile-width-50 {
        width: 50%;
    }

    .hw-mobile-width-90 {
        width: 90%;
    }
}

/* table desktop */
@media (min-width: 600px) {
    .hw-mobile-table-title-show {
        display: none;
    }
}

/* Popup title */
.hw-popup-title {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 1em;
}

@media (max-width: 600px) {
    .hw-popup-title {
        font-family: "Roboto";
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 1em;
    }
}

/* mat-label szimuláció */
.hw-mat-label {
    font-size: 13px;
    font-family: "Roboto";
    font-weight: 500;
    color: #9f9f99;
}

/* Grid title + rounded panel */
.hw-grid-title {
    font-size: 16px;
    font-weight: 600;
    padding-top: 1.5em;
}

.hw-rounded-panel {
    overflow: hidden;
    padding: 0px 25px 0px 25px !important;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    box-shadow: 0px 0px 1px -1px rgb(0 0 0 / 10%), 0px 0px 4px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
    background-color: white;
}

/* Blue div */
.blue-sided-div {
    border-left: 3px solid #03a9f4;
    padding-left: 1.5em !important;
    margin-top: 1em;
    margin-bottom: 1em;
}

/* Toolttip */
.hw-tooltip {
    color: white !important;
    background-color: #35363a;
    border: #35363a !important;
    border-radius: 5px !important;
}

.hw-tooltip-white {
    color: black !important;
    background-color: white;
    border: rgb(134, 192, 243) !important;
    border-radius: 5px !important;
    float: right !important;
}

.hw-tooltip-dark {
    color: white !important;
    background-color: #35363a;
    border: #35363a !important;
    border-radius: 5px !important;
    float: right !important;
}


.hw-tooltip-white-space {
    white-space: pre-line;
    text-align: justify;
    float: right !important;
}

.hw-error {
    color: red;
}

.col-horizontal-center {
    margin-top: auto;
    margin-bottom: auto;
}

.hw-center-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    padding: 10px;
}

// Chat float button
.command-button {
    float: right;
    width: 68px;
    margin-left: 10px;
    margin-bottom: 3em;
}

@media screen and (max-width: 840px) {
    .command-button[_ngcontent-ng-c510415203] {
        margin-bottom: 1em;
    }
}

.add-icon {
    color: #fff !important;
}

.float-button {
    background-color: #41c300 !important;
    border: none !important;
    position: fixed !important;
    right: 1em !important;
    bottom: 0 !important;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50% !important;
    cursor: pointer !important;
    box-shadow: 0 2px 5px #666 !important;
    z-index: 1;
}

/* INPUT OVERRIDE */
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.mat-mdc-form-field-infix {
    min-height: 39px !important;
}

.cdk-overlay-dark-backdrop {
    background: rgb(0 0 0 / 63%) !important;
}

.mat-badge-content {
    background-color: red !important;
}